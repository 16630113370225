<template>
  <v-container fluid>
    <h1 class="text-h5 primary--text pa-3 mb-7">Dashboard</h1>

    <v-row dense>
      <v-col
        cols="12"
        md="6"
      >
        <v-row
          class="mb-5"
          dense
        >
          <v-col v-show="showDashboardUsersCard">
            <SimpleCard
              :title="cards[0].title"
              :amount="cards[0].amount"
              :color="cards[0].color"
              :dark="cards[0].dark"
              :icon="cards[0].icon"
              :active="cards[0].active"
              :inactive="cards[0].inactive"
            />
          </v-col>
          <v-col v-show="showDashboardSellersCard">
            <SimpleCard
              :title="cards[1].title"
              :amount="cards[1].amount"
              :color="cards[1].color"
              :dark="cards[1].dark"
              :icon="cards[1].icon"
              :active="cards[1].active"
              :inactive="cards[1].inactive"
            />
          </v-col>
          <v-col v-show="showDashboardClientsCard">
            <SimpleCard
              :title="cards[2].title"
              :amount="cards[2].amount"
              :color="cards[2].color"
              :dark="cards[2].dark"
              :icon="cards[2].icon"
              :active="cards[2].active"
              :inactive="cards[2].inactive"
            />
          </v-col>
        </v-row>
        <TableCard
          v-show="showDashboardUsersByProfileCard"
          title="Usuários por perfil"
          icon="fas fa-id-card"
          :headers="headers"
          :items="tableItems"
          color="primary-color"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <TableCard
          v-show="showDashboardClientsBySellerCard"
          title="Clientes por vendedor"
          icon="fas fa-grin-stars"
          :headers="headersClient"
          :items="tableItemsClient"
          color="accent-color"
        />
        <TwoColumnCard
          class="mt-5"
          :items="clientItems"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission.js'
export default {
  name: 'PeopleDashboard',
  components: {
    SimpleCard: () => import('@/components/dashboard/SimpleCard'),
    TableCard: () => import('@/components/dashboard/TableCard'),
    TwoColumnCard: () => import('@/components/dashboard/TwoColumnCard'),
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    dash: null,
    headers: [
      { text: 'Perfil', value: 'profile_name', sortable: false },
      { text: 'Usuários', value: 'amount', sortable: false, align: 'center' },
    ],
    tableItems: [],
    headersClient: [
      { text: 'Vendedor', value: 'seller_name', sortable: false },
      {
        text: 'Clientes',
        value: 'client_amount',
        sortable: false,
        align: 'center',
      },
    ],
    tableItemsClient: [],
    cards: [
      {
        title: 'Usuários',
        amount: 0,
        color: 'primary-color',
        dark: true,
        icon: 'fas fa-users',
        active: 0,
        inactive: 0,
      },
      {
        title: 'Vendedores',
        amount: 0,
        color: 'success-color',
        dark: true,
        icon: 'fas fa-comments-dollar',
        active: 0,
        inactive: 0,
      },
      {
        title: 'Clientes',
        amount: 0,
        color: 'accent-color',
        dark: true,
        icon: 'fas fa-grin-stars',
        active: 0,
        inactive: 0,
      },
    ],
    clientItems: [
      {
        title: 'Clientes PF',
        amount: null,
      },
      {
        title: 'Clientes PJ',
        amount: null,
      },
    ],
  }),
  computed: {
    ...mapGetters('people', [
      'showDashboardClientsCard',
      'showDashboardSellersCard',
      'showDashboardUsersCard',
      'showDashboardUsersByProfileCard',
      'showDashboardClientsBySellerCard',
    ]),
  },
  methods: {
    getDash() {
      this.$api
        .get(`people/dashboard`, {
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.dash = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.setUserCards()
          this.setSellerCards()
          this.setClientCards()
          this.setClientItems()
          this.setTableItemsClient()
          this.setTableItems()
        })
    },
    setUserCards() {
      this.cards[0].amount = this.dash.users.amount
      this.cards[0].active = this.dash.users.active
      this.cards[0].inactive = this.dash.users.inactive
    },
    setSellerCards() {
      this.cards[1].amount = this.dash.sellers.amount
      this.cards[1].active = this.dash.sellers.active
      this.cards[1].inactive = this.dash.sellers.inactive
    },
    setClientCards() {
      this.cards[2].amount = this.dash.clients.amount
      this.cards[2].active = this.dash.clients.active
      this.cards[2].inactive = this.dash.clients.inactive
    },
    setClientItems() {
      this.clientItems[0].amount = this.dash.clients.pf
      this.clientItems[1].amount = this.dash.clients.pj
    },
    setTableItemsClient() {
      this.tableItemsClient = this.dash.seller_client
    },
    setTableItems() {
      this.tableItems = this.dash.profiles
    },
  },
  created() {
    this.getDash()
  },
}
</script>
